<script>
import { mapGetters, mapMutations } from 'vuex'
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	computed: {
		client() {
			return this.clientById(Number(this.$route.params.id))
		},
		...mapGetters({
			clientById: 'arceClients/getById',
			groupStructure: 'arceClients/groupStructure',
		})
	},
	methods: {
		initWizard() {
			this.setArceClient({...this.client})
			
			this.initArceWizard({
				data: {
					start: true,
					client: {...this.client},
					groupStructure: this.groupStructure(this.client.id)
				},
				steps: [
					{
						sort: 1,
						key: 'start',
						status: 'current'
					},
				]
			})

			this.showArceWizard()
		},
		...mapMutations({
			setArceClient: 'arceStatus/setArceClient',
			showArceWizard: 'arceStatus/showArceWizard',
			initArceWizard: 'arceStatus/initArceWizard',
		})
	},
	mounted() {
		this.initArceWizard()
		this.showSteps('myTourClient')
	}
}
</script>
<template>
	<div class="d-flex flex-wrap px-1 py-1 s-single">
		<div class="col-12 p-0">
			<div class="d-flex flex-wrap py-1">
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						<div class="s-panel__header bg-light p-3">
							<div class="d-flex align-items-center">
								<div class="s-element__icon">
									<img src="@/assets/simulator/account_120.png" alt="">
								</div>
								<div>
									<p class="m-0">{{client.type}}</p>
									<h3 class="title">{{client.name}} {{client.code}}</h3>
								</div>
								<div class="ml-auto d-flex">
									<button class="s-btn s-btn--outline">
										<SvgIcon name="add"/>
										Seguir
									</button>

									<div class="ml-3 s-btn-group">
										<button data-v-step="c1" @click="initWizard" class="s-btn s-btn--outline">
											ARCE
										</button>
										<button class="s-btn s-btn--outline">
											Ampliar detalle
										</button>
										<button class="s-btn s-btn--outline">
											Nueva Visita
										</button>
										<button class="s-btn s-btn--outline px-2">
											<svg-icon style="font-size: 1.2em" name="down" />
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="s-panel__body p-3">
							<div class="d-flex">
								<div class="col-2">
									<p class="m-0">Ventas año actual</p>
								</div>
								<div class="col-1">
									<p class="m-0">BAI actual</p>
								</div>
								<div class="col-1">
									<p class="m-0">Segmento</p>
								</div>
								<div class="col-2">
									<p class="m-0">No aplica cetificación EU</p>
									<input type="checkbox" name="" id="">
								</div>
								<div class="col-2">
									<p class="m-0">Nombre del ejecutivo</p>
									<p class="m-0"><a href="#">Javier Soto</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-8 col-xl-9 p-0">
			<div class="d-flex flex-wrap">
				<div class="col-12 px-2 py-2">
					<div class="s-panel h-100">
						<div class="s-panel__body p-0 position-relative h-100">
							<div class="d-flex flex-wrap h-100 position-relative w-100">
								<div class="col-12 col-md-6 col-lg-3 p-2 h-md-100">
									<div class="border h-100 p-2">
										<h2 class="title m-0">GENERAL</h2>
										<div class="pl-3">
											<p class="">BAI actual</p>
											<p>Subsidios</p>
											<p class="m-0">Top divisional</p>
											<p class="m-0">
												<input type="checkbox" name="" id="">
											</p>
											<p>Satisfacción del cte.</p>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-6 col-lg-3 p-2 h-md-100">
									<div class="border h-100 p-2">
										<h2 class="title m-0">RIESGOS</h2>
										<div class="pl-3">
											<p class="">Rating</p>
											<p class="m-0">Workout</p>
											<p class="m-0">
												<input type="checkbox" name="" id="">
											</p>
											<p>Estrategia</p>
											<p class="m-0">PLD</p>
											<p class="m-0">
												<input type="checkbox" name="" id="">
											</p>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-6 col-lg-3 p-2 h-md-100">
									<div class="border h-100 p-2">
										<h2 class="title m-0">LÍMITES</h2>
										<div class="pl-3">
											<p class="">Vencimiento PF</p>
											<p class="">Límite autorizado</p>
											<p class="">Límite fromalizado</p>
											<p class="">Dispuesto</p>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-6 col-lg-3 p-2 h-md-100">
									<div class="border h-100 p-2">
										<h2 class="title m-0">CAPTACIÓN</h2>
										<div class="pl-3">
											<p class="">Saldo en captación</p>
											<p class="">Potencial</p>
											<p class="">No. de nominados</p>
											<p class="">Ventas año actual</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 p-2">
					<div class="s-drop-file d-inline-block">
						<button class="s-btn s-btn--outline">
							<svg-icon name="upload" />
							Cargar archivos
						</button>
						<span class="px-2">O suelte archivos</span>
					</div>
				</div>

				<div class="col-12 px-2 py-2">
					<div class="s-panel h-100">
						<div class="s-panel__body p-3 position-relative h-100">
							<div class="s-nav-tab">
								<button class="s-nav-tab__item s-nav-tab__item--active">
									GENERAL
								</button>
								<button class="s-nav-tab__item">
									NEGOCIO BBVA
								</button>
								<button class="s-nav-tab__item">
									RIESGO
								</button>
								<button class="s-nav-tab__item">
									POTENCIAL BBVA
								</button>
							</div>
							<div class="py-2">
								<div class="s-collapse__header" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
									<div class="d-flex">
										<h1 class="title"><svg-icon name="chevrondown" /> <span>DATOS DEL CLIENTE</span></h1>
									</div>
								</div>
								<div id="collapseOne" class="collapse show" aria-labelledby="headingOne">
									<div class="d-flex flex-wrap">
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Tipo de registro del cliente</p>
												<p class="font-weight-bold m-0">Cliente Empresa</p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Nombre del ejecutivo</p>
												<p class="m-0"><a href="#">JORGE HIDALGO ROBLES</a></p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Nombre comercial del cliente</p>
												<p class="m-0">Cliente {{client.code}}</p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Consejero</p>
												<p class="m-0"><input type="checkbox" name="" id=""></p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">RFC</p>
												<p class="m-0">{{client.rfc}}</p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Segmento</p>
												<p class="m-0"> </p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Razón social</p>
												<p class="m-0"> </p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Crossborder</p>
												<p class="m-0"><input type="checkbox" name="" id=""></p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">No. de cliente</p>
												<p class="m-0">{{client.code}}</p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Top divisional</p>
												<p class="m-0"><input type="checkbox" name="" id=""></p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Teléfono</p>
												<p class="m-0"> </p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Top regional</p>
												<p class="m-0"><input type="checkbox" name="" id=""></p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Número de Grupo Económico</p>
												<p class="m-0 py-2"></p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Grupo al que pertenece</p>
												<p class="m-0 py-2"></p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Grupo Económico al que pertenece</p>
												<p class="m-0">
													<router-link v-if="client.parent" :to="{name: 'arce-client', params: {id: client.parent.id}}">{{client.parent.name}}</router-link>
												</p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Sitio web</p>
												<p class="m-0 py-2"></p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Ventas año actual</p>
												<p class="m-0 py-2"></p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Dirección de facturación</p>
												<p class="m-0 py-2"></p>
											</div>
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Sector</p>
												<p class="m-0 py-2"></p>
											</div>
										</div>
										<div class="col-12 col-md-6">
										
										</div>
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Actividad</p>
												<p class="m-0 py-2"></p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="py-2">
								<div class="s-collapse__header" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
									<div class="d-flex">
										<h1 class="title"><svg-icon name="chevrondown" /> <span>Segmento Nuevo</span></h1>
									</div>
								</div>
								<div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo">
									<div class="d-flex flex-wrap">
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Segmento</p>
												<p class="font-weight-bold m-0 py0-2"></p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="py-2">
								<div class="s-collapse__header" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
									<div class="d-flex">
										<h1 class="title"><svg-icon name="chevrondown" /> <span>DATOS DE OFICINA</span></h1>
									</div>
								</div>
								<div id="collapse3" class="collapse show" aria-labelledby="headingTwo">
									<div class="d-flex flex-wrap">
										<div class="col-12 col-md-6">
											<div class="border-bottom h-100 py-2">
												<p class="m-0">Director regional</p>
												<p class="font-weight-bold m-0 py0-2"></p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="py-2">
								<div class="s-collapse__header" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
									<div class="d-flex">
										<h1 class="title"><svg-icon name="chevrondown" /> <span>CONTACTABILIDAD</span></h1>
									</div>
								</div>
								<div id="collapse3" class="collapse show" aria-labelledby="headingTwo">
									<div class="d-flex flex-wrap">
										<div class="col-12 p-0">
											<div class="alert alert-warning w-100 text-center" role="alert">
												<svg-icon name="warning"/>	No existen Visitas a este cliente
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-4 col-xl-3 p-0">
			<div class="d-flex flex-wrap">
				<div class="col-12 px-2 py-2">
					<div class="s-panel">
						
						<div class="s-panel__body">
							<div class="s-nav-tab">
								<button class="s-nav-tab__item s-nav-tab__item--active">
									Relacionado
								</button>
								<button class="s-nav-tab__item">
									Actividad
								</button>
								<button class="s-nav-tab__item">
									Chatter
								</button>
							</div>
							<div class="py-1">
								<div class="s-collapse__header py-3 border" data-toggle="collapse" data-target="#aside1" aria-expanded="true" aria-controls="aside1">
									<div class="d-flex">
										<p class="font-weight-bold m-0">
											<span class="s-element__icon s-element__icon--24 s-element__icon--orange">
												<img src="@/assets/simulator/opportunity_120.png" alt="">
											</span>
											<span>Oportunidades (0)</span>
										</p>
									</div>
								</div>
							</div>
							<div class="py-1">
								<div class="s-collapse__header py-3 border" data-toggle="collapse" data-target="#aside2" aria-expanded="true" aria-controls="aside2">
									<div class="d-flex">
										<p class="font-weight-bold m-0">
											<span class="s-element__icon s-element__icon--24 s-element__icon--pink">
												<img src="@/assets/simulator/operating_hours_120.png" alt="">
											</span>
											<span>Alertas CRM (0)</span>
										</p>
									</div>
								</div>
							</div>
							<div class="py-1">
								<div class="s-collapse__header py-3 border" data-toggle="collapse" data-target="#aside3" aria-expanded="true" aria-controls="aside3">
									<div class="d-flex">
										<p class="font-weight-bold m-0">
											<span class="s-element__icon s-element__icon--24 s-element__icon--yellow">
												<img src="@/assets/simulator/case_120.png" alt="">
											</span>
											<span>Casos (0)</span>
										</p>
									</div>
								</div>
							</div>
							<div class="py-1">
								<div class="s-collapse__header py-3 border" data-toggle="collapse" data-target="#aside3" aria-expanded="true" aria-controls="aside3">
									<div class="d-flex">
										<p class="font-weight-bold m-0">
											<span class="s-element__icon s-element__icon--24 s-element__icon--purple">
												<img src="@/assets/simulator/contact_120.png" alt="">
											</span>
											<span>Contactos (0)</span>
										</p>
									</div>
								</div>
							</div>
							<div class="py-1">
								<div class="s-collapse__header py-3 border" data-toggle="collapse" data-target="#aside3" aria-expanded="true" aria-controls="aside3">
									<div class="d-flex">
										<p class="font-weight-bold m-0">
											<span class="s-element__icon s-element__icon--24 s-element__icon--red">
												<img src="@/assets/simulator/user_120.png" alt="">
											</span>
											<span>Plan de cuenta (0)</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>	
	</div>
</template>