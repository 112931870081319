import { mapGetters } from "vuex"

export default {
	computed: {
		...mapGetters({
			showHelp: 'arceStatus/showHelp',
		})
	},
	methods: {

		hiddenSteps() {
			let vm = this
			Object.keys(vm.$tours).forEach(key => {
				vm.$tours[key].stop()
			})
		},
		showSteps(name, step = 0) {
			let vm = this
			if(!vm.showHelp && !vm.showHelpPF) {
				return 
			}
			setTimeout(() => {
				if(step === 0) {
					vm.hiddenSteps()
				}
				debugger
				vm.$tours.myTour.start()
				setTimeout(() => {
					vm.$tours[name].currentStep = step
				}, 10)
			}, 1000)
		},
		goToStep(name, step) {
			let vm = this
			if(!vm.showHelp && !vm.showHelpPF) {
				return 
			}
			setTimeout(() => {
				vm.$tours[name].currentStep = step
			}, 300)
		}
	}
  }